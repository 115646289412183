.pf.pf-modal-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0008;
  overflow: auto;
  transition: all 500ms ease;
  opacity: 0;
  visibility: hidden;
}

.pf.pf-modal-screen[aria-hidden=false] {
  opacity: 1;
  visibility: visible;
}

.pf.pf-modal-screen .pf.pf-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10vh auto;
  max-width: 900px;
  transition: all 500ms ease;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20%);
}

.pf.pf-modal-screen .pf.pf-modal[aria-hidden=false] {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}