.pf.input-container {
  display: flex;
  flex-direction: column;
}

.pf.input-label {
  display: block;
  margin-bottom: var(--size-xs);
}

.pf.input-error {
  display: block;
  margin-top: var(--size-s);
  font-size: var(--text-size-s);
  color: var(--failure);
  margin-top: var(--size-xs);
}

.pf.input-wrapper {
  border-radius: var(--radius-s);
  display: flex;
  align-items: center;
}

.pf.input {
  flex: 1;
  font-family: var(--theme-font);
  display: block;
  background: none;
  border: none;
  color: var(--black);
  font-size: var(--text-size-m);
  border-radius: var(--radius-s);
  padding: 0 var(--size-s);
}

.pf.input:not(.multiline) {
  height: var(--input-height);
}

.pf.input.multiline {
  padding: var(--size-s);
}

.pf.input::placeholder {
  opacity: 0.6;
}

.pf.input:focus {
  outline: none !important;
}

input.pf.input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input.pf.input[type=number]::-webkit-inner-spin-button,
input.pf.input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.pf.input.alt {
  padding: 0;
  border-radius: 0;
}

.pf.pf-container.main {
  .pf.input-label {
    color: var(--text-main-primary);
  }

  .pf.input-wrapper {
    border-radius: var(--radius-l);
    border: 1px solid var(--input-main-border);
  }

  .pf.input {
    color: var(--text-main-primary);
    background: var(--input-main-background);
    border-radius: var(--radius-l);
    padding: 0 var(--size-m);
  }

  .pf.input-wrapper.multiline {
    border-radius: var(--radius-s);
  }

  .pf.input.multiline {
    padding: var(--size-s);
  }

  .pf.input::placeholder {
    color: var(--input-main-placeholder);
  }
}

.pf.pf-container.toolbar {
  .pf.input-label {
    color: var(--text-toolbar-primary);
  }

  .pf.input-wrapper {
    background: var(--input-toolbar-background);
    border: 1px solid var(--input-toolbar-border);
  }

  .pf.input {
    color: var(--text-toolbar-primary);
  }

  .pf.input-wrapper:focus-within {
    border: 1px solid var(--input-toolbar-placeholder);
  }

  .pf.input::placeholder {
    color: var(--input-toolbar-placeholder);
  }

  .pf.input-wrapper.alt {
    background: none;
    border: 1px solid var(--transparent);
    border-radius: 0;
    border-bottom: 1px solid var(--stroke-toolbar);
  }

  .pf.input-wrapper.alt:focus-within {
    border-bottom: 1px solid var(--input-toolbar-placeholder);
  }

  .pf.input-wrapper.alt.error {
    border: 1px solid var(--transparent);
    border-bottom: 1px solid var(--failure);
  }
}

.pf.pf-container.editor {
  .pf.input-label {
    color: var(--text-editor-primary);
  }

  .pf.input-wrapper {
    background: var(--input-editor-background);
    border: 1px solid var(--input-editor-border);
  }

  .pf.input {
    color: var(--text-editor-primary);
  }

  .pf.input-wrapper:focus-within {
    border: 1px solid var(--input-editor-placeholder);
  }

  .pf.input::placeholder {
    color: var(--input-editor-placeholder);
  }

  .pf.input-wrapper.alt {
    background: none;
    border: 1px solid var(--transparent);
    border-radius: 0;
    border-bottom: 1px solid var(--stroke-editor);
  }

  .pf.input-wrapper.alt:focus-within {
    border-bottom: 1px solid var(--input-editor-placeholder);
  }

  .pf.input-wrapper.alt.error {
    border: 1px solid var(--transparent);
    border-bottom: 1px solid var(--failure);
  }
}

.pf.pf-container.form {
  .pf.input-label {
    color: var(--text-form-primary);
  }

  .pf.input-wrapper {
    background: var(--input-form-background);
    border: 1px solid var(--input-form-border);
  }

  .pf.input {
    color: var(--text-form-primary);
  }

  .pf.input-wrapper:focus-within {
    border: 1px solid var(--input-form-placeholder);
  }

  .pf.input::placeholder {
    color: var(--input-form-placeholder);
  }

  .pf.input-wrapper.alt {
    background: none;
    border: 1px solid var(--transparent);
    border-radius: 0;
    border-bottom: 1px solid var(--stroke-form);
  }

  .pf.input-wrapper.alt:focus-within {
    border-bottom: 1px solid var(--input-form-placeholder);
  }

  .pf.input-wrapper.alt.error {
    border: 1px solid var(--transparent);
    border-bottom: 1px solid var(--failure);
  }
}

.pf.pf-container .pf.input-wrapper.error {
  border: 1px solid var(--failure);
}