.pf-design-editor {
	font-size: 14px;
	user-select: none;
}

.pf-design-editor__section-title {
	padding: 8px 0;
	font-size: 13px;
	display: flex;
	align-items: center;
	gap: 6px;
	cursor: pointer;

	& i {
		font-size: 18px;
		color: var(--purple-100);
	}
}

.pf-design-editor__section--up {
	height: 0 !important;
	overflow: hidden !important;
}

.pf-design-editor__section--down {
	height: 100%;
}

.pf-designer-editor__rule {
	padding: 4px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pf-designer-editor__rule--vertical {
	flex-direction: column;
}

.pf-design-editor__rule-title {
	font-size: 12px;
	text-transform: capitalize;
}

.pf-design-editor__sizing-control-input {
	.pf.input {
		font-size: 12px !important;
		height: 28px !important;
	}
}

.pf-design-editor__sizing-control,
.pf-design-editor__sizing-control--space {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 28px;
	width: 100%;

	& .input-container {
		width: inherit;
	}

	& .pf.input {
		border-radius: 0;
	}

	& .pf.input.number {
		padding: 0 2px;
		min-width: 45px;
		width: 100%;
		height: inherit;
		text-align: right;
	}

	& .select-container > div {
		height: 28px !important;
	}

	& .pf.button.support.pf.select {
		border-color: transparent !important;
		padding: 0;
		height: unset;
		min-width: 10px;
		width: fit-content;
		background: none;
	}

	& .text-component {
		font-size: 12px;
		text-align: center;
		color: var(--purple-100) !important;
	}

	& i {
		display: none;
	}
}

.pf-design-editor__sizing-control--space {
	transform: rotate(180deg);
	width: 60px;

	& .pf.input.number {
		min-width: 18px;
		font-size: 10px !important;
	}

	& .pf.input.number:focus {
		border: none !;
	}

	& .pf.input-wrapper {
		background: transparent !important;
	}

	& .pf.input-wrapper:focus-within {
		border-color: transparent !important;
	}

	& .select-container > div {
		height: 26px !important;
		min-width: 30px;
	}

	& .pf.button.support.pf.select {
		transform: initial;
	}

	& .text-component {
		margin-left: 0;
	}

	& .text-component {
		margin-top: -2px;
		font-size: 9px;
	}
}

.pf-design-editor__dropdown {
	border: none !important;
	height: 28px !important;
	background-color: var(--purple-400) !important;

	&:focus-within {
		border: 1px solid var(--purple-100) !important;
	}

	& > div {
		width: -webkit-fill-available;
	}

	& .text-component {
		font-size: 12px;
		color: var(--white) !important;
	}
}

.pf-design-editor__sizing-control--small {
	margin-right: 8px;
	width: 84px;
}

.pf-design-editor__sizing-control--xsmall {
	width: 71px;
}

.pf-design-editor__control {
	border-radius: 4px;
	width: 100%;
	display: flex;
	& > div {
		width: 100%;
	}
}

.pf-design-editor__control--small {
	width: 176px;
}

.pf-design-editor__button {
	padding: 6px;
	height: 26px;
	flex: 1;
	color: var(--purple-100);
	font-size: 14px;
	text-align: center;
	background-color: var(--purple-400);
	cursor: default;
}

.pf-design-editor__button:first-child,
.pf-design-editor__button-text:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.pf-design-editor__button:last-child,
.pf-design-editor__button-text:last-child {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.pf-design-editor__button-text {
	border-radius: 4px;
	padding: 5px 8px;
	height: 26px;
	color: var(--purple-100);
	background-color: var(--purple-400);
	font-size: 12px;
}

.pf-design-editor__button-text:hover,
.pf-design-editor__button-text--active,
.pf-design-editor__button:hover,
.pf-design-editor__button--active {
	border-radius: 4px;
	color: var(--white);
	background: var(--purple-300);
}

.pf-editor-grid-template {
}

.pf-editor-grid-header {
	display: flex;
	justify-content: space-between;
}

.pf-editor-grid-add,
.pf-editor-grid-remove {
	font-size: 16px;
	color: var(--purple-100);
	cursor: pointer;

	&:hover {
		color: var(--purple-200);
	}
}

.pf-editor-grid-remove {
	float: right;
}

.pf-editor-grid-template-rule {
	border-bottom: 1px solid var(--purple-300);
	padding: 8px 4px 0;
}

.pf-editor-grid-template-toggler {
	border-radius: 8px;
	height: 24px;
	display: flex;
	justify-content: center;
	cursor: pointer;

	& :first-child {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}

	& :last-child {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}

.pf-editor-grid-template-default,
.pf-editor-grid-template-minmax {
	padding: 4px 8px;
	width: 100px;
	height: 100%;
	background: var(--purple-400);
	color: var(--purple-100);
	font-size: 11px;
	text-align: center;

	&:hover {
		background-color: var(--purple-300);
	}

	&.active {
		color: var(--white);
		background-color: var(--purple-300);
	}
}

.pf-editor-grid-template-controls {
	padding: 12px 0;
	display: flex;
	width: 100%;
	align-items: center;
}

.pf-editor-control {
	border: 1px solid transparent;
	border-radius: 8px;
	padding: 4px;
	width: 120px;
	height: 28px;
	display: flex;
	align-items: center;
	background-color: var(--purple-400);

	& > div {
		width: -webkit-fill-available;
	}

	&:focus-within {
		border: 1px solid var(--purple-100);
	}
}

.pf-editor-input {
	border-radius: 0;
	flex-grow: 1 !important;

	& .pf.input {
		border-radius: 0;
		padding: 0;
		height: auto;
		width: 50%;
		text-align: right;
	}

	&.pf.input-wrapper {
		border-radius: 0;
		border: none !important;
		background-color: transparent !important;
	}

	&:focus-within {
		border: none !important;
	}
}

.pf-editor-dropdown {
	overflow: hidden;
	display: block;

	&.pf.button.select {
		border: none !important;
		padding: 0 4px;
		height: 28px;
		width: fit-content;
	}

	&.single {
		margin: 0 auto;
	}

	&:focus-within {
		border: none;
	}

	& .text-component {
		font-size: 12px;
		color: var(--purple-100) !important;
	}

	& i {
		display: none;
	}
}

.pf-mui-dropdown .MuiInputBase-formControl.MuiInputBase-sizeSmall {
	font-size: inherit;
}

.pf-mui-dropdown
	.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
	padding: 3px 6px;
}
