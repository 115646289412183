.custom-scroll::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  background: var(--purple-50);
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: var(--purple-100);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: var(--purple-100);
}
