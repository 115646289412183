.pf.flow-node-icon-container {
  height: 60px;
  aspect-ratio: 1;
  border-radius: var(--radius-s);
  background: var(--purple-50);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pf.flow-node-icon {
  height: 40%;
  aspect-ratio: 1;
  border-radius: 4px;
  background: linear-gradient(76.27deg, #916BFE 10.12%, #C7B4FF 100.91%);
}

.pf.node-summary-options {
  opacity: 0;
  transition: opacity 300ms ease;
}

.pf.node-summary:hover .pf.node-summary-options {
  opacity: 1;
}

.pf.flow-node-icon.CONDITIONAL_SWITCH,
.pf.flow-node-icon.CONDITIONAL_IFELSE {
  transform: rotate(45deg);
}

.pf.flow-node-icon.ACTION {
  height: 45%;
  border-radius: 50%;
}

.pf.button.node-type-selection-button:hover {
  opacity: 1;
}

.pf.flow-node-icon-button {
  height: 40px;
  aspect-ratio: 1;
  border-radius: 4px;
  border: 3px solid transparent;
  background:
    linear-gradient(76.27deg, #916BFE 10.12%, #C7B4FF 100.91%) border-box;
  mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  transition: all 200ms ease;
}

.pf.button.node-type-selection-button:hover .pf.flow-node-icon-button {
  mask: none;
  -webkit-mask: none;
}

.pf.flow-node-icon-button.CONDITIONAL {
  height: 32px;
  /* height: 28px; */
  transform: rotate(45deg);
}

.pf.flow-node-icon-button.UI_ACTION {
  border-radius: 50%;
}

.pf.button.flow-dismiss-button {
  background: var(--stroke-form) !important;
  border-radius: 50%;
  padding: 0 !important;
  height: 30px !important;
  aspect-ratio: 1;
}