.default-input {
  color-scheme: dark;
  background-color: var(--purple-400);
  border-radius: var(--radius-s);
  padding: var(--size-s);
  width: 100%;
  border: none;
}

.default-input:focus-within {
  outline: solid var(--purple-100) 1px;
}
