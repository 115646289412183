.spacing-control {
	position: relative;
	height: 200px;
	margin: 0 10px;
	border-radius: 2rem;
	overflow: hidden;
}

.spacing-input {
	width: 30px;
	position: absolute;
	background-color: transparent;
	font-size: var(--text-label);
	div > div {
		border-color: transparent !important;
	}
}

.margin-left {
	bottom: 87px;
	left: -5px;
}
.margin-top {
	right: 50%;
	top: 10px;
}
.margin-right {
	z-index: 1;
	right: 20px;
	bottom: 85px;
}
.margin-bottom {
	bottom: 10px;
	right: 50%;
	z-index: 2;
}

.padding-left {
	bottom: 146px;
	left: 42px;
}
.padding-top {
	right: 25px;
	bottom: 172px;
}
.padding-bottom {
	right: 25px;
	bottom: 115px;
}
.padding-right {
	bottom: 145px;
	right: -37px;
}

.spacing-input > div > div {
	background-color: transparent !important;
}
.spacing-input > div > div > input {
	min-width: unset !important;
	width: 30px !important;
	background-color: transparent !important;
}

.spacing-input > div > div > input:focus {
	border: none !important;
	outline: none !important;
}

/*-------*/
.rectangle {
	position: relative;
	height: 200px;
	margin: 10px;
}
.inner-rectangle {
	position: relative;
	height: 120px;
	width: 170px;
	margin: 10px;
	right: -35px;
	bottom: -40px;
}
.trapezoid-m-left {
	position: absolute;
	border-left: 45px solid var(--purple-300);
	border-top: 40px solid transparent;
	border-bottom: 40px solid transparent;
	width: 0;
	height: 200px;
	left: 0;
}
.trapezoid-m-right {
	position: absolute;
	border-right: 40px solid var(--purple-300);
	border-top: 40px solid transparent;
	border-bottom: 40px solid transparent;
	width: 0;
	height: 200px;
	right: 0;
}

.trapezoid-m-bottom {
	position: absolute;
	border-right: 38px solid var(--purple-400);
	border-top: 40px solid transparent;
	border-bottom: 40px solid transparent;
	width: 0;
	height: 255px;
	rotate: 90deg;
	right: 108px;
	bottom: -108px;
}

.trapezoid-m-top {
	position: absolute;
	border-right: 38px solid var(--purple-400);
	border-top: 40px solid transparent;
	border-bottom: 40px solid transparent;
	width: 0;
	height: 255px;
	rotate: 270deg;
	right: 109px;
	bottom: 53px;
}

.trapezoid-p-left {
	position: absolute;
	border-left: 49px solid var(--purple-300);
	border-top: 50px solid transparent;
	border-bottom: 50px solid transparent;
	width: 0;
	height: 116px;
	left: 47px;
	bottom: 42px;
}

.trapezoid-p-right {
	position: absolute;
	border-left: 50px solid var(--purple-300);
	border-top: 50px solid transparent;
	border-bottom: 50px solid transparent;
	width: 0;
	height: 116px;
	right: 42px;
	bottom: 42px;
	rotate: 180deg;
}

.trapezoid-p-top {
	position: absolute;
	border-right: 48px solid var(--purple-400);
	border-top: 50px solid transparent;
	border-bottom: 50px solid transparent;
	width: 0;
	height: 171px;
	rotate: 270deg;
	right: 102px;
	bottom: 50px;
}

.trapezoid-p-bottom {
	position: absolute;
	border-right: 50px solid var(--purple-400);
	border-top: 50px solid transparent;
	border-bottom: 50px solid transparent;
	width: 0;
	height: 171px;
	rotate: 90deg;
	right: 102px;
	bottom: -21px;
}
