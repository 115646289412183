.pf.link {
  transition: opacity 200ms ease;
}

.pf.link:hover {
  opacity: 0.8;
}

.pf.link.block {
  display: block;
}

.pf.link.primary {
  text-decoration: none;
  font-weight: 600;
}

.pf.pf-container.main .pf.link {
  color: var(--link-main);
}

.pf.pf-container.toolbar .pf.link {
  color: var(--link-toolbar);
}

.pf.pf-container.editor .pf.link {
  color: var(--link-editor);
}

.pf.pf-container.form .pf.link {
  color: var(--link-form);
}