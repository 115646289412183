.pf.pf-container.toolbar {
	background: var(--container-toolbar-background);
	overflow-x: auto;
	height: calc(100% - 94px);
}

.pf.pf-container.toolbar::-webkit-scrollbar {
	height: 8px;
	background: var(--purple-saturated);
}

.pf.pf-container.toolbar::-webkit-scrollbar-thumb {
	background: var(--purple-200);
	border-radius: 10px;
}

.pf.pf-container.toolbar::-webkit-scrollbar-thumb:hover {
	background: var(--purple-100);
}

.pf.pf-container.toolbar::-webkit-scrollbar-corner {
	background: var(--container-editor-background);
}

.pf.pf-container.editor {
	background: var(--container-editor-background);
}

.pf.pf-container.form {
	background: var(--container-form-background);
}
