html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

/* >>> GENERAL <<< */

:root {
  --white: #ffffff;
  --black: #000000;
  --purple-25: #f8f6fe;
  --purple-50: #ece5ff;
  --purple-100: #ad90ff;
  --purple-100-50: #ad90ff80;
  --purple-150: #916bfe;
  --purple-200: #7e26ef;
  --purple-250: #3f3559;
  --purple-300: #342c49;
  --purple-350: #231d32;
  --purple-400: #1e192b;
  --purple-500: #181524;
  --purple-600: #0f0c1b;
  --purple-700: #0b0614;
  --purple-saturated: #150029;
  --gray-100: #655e78;
  --disabled: #777;
  --transparent: #0000;

  --font-1: Work Sans;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

section.full-height {
  height: 100vh;
}

/* >>> CONTENT <<< */

.pf {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.pf.app {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pf.safe-area {
  max-width: var(--max-width);
}

/* >>> THEMES <<< */

.pf.default-theme {
  /** FONT **/
  font-family: var(--font-1);
  --theme-font: var(--font-1);

  /** COLORS **/
  /* GENERAL */
  --primary: var(--purple-200);
  --secondary: var(--purple-100);
  --strokes: var(--purple-50);
  --contrast: var(--white);
  --background: var(--purple-saturated);
  --table-strokes: var(--purple-100-50);
  /* CONTAINERS */
  --container-main-background: var(--purple-saturated);
  --container-form-background: var(--white);
  --container-toolbar-background: var(--purple-500);
  --container-editor-background: var(--purple-600);
  --container-header-background: var(--purple-700);
  /* STROKES */
  --stroke-main: var(--purple-100);
  --stroke-form: var(--purple-50);
  --stroke-toolbar: var(--purple-300);
  --stroke-editor: var(--purple-100);
  /* INPUTS */
  --input-main-background: var(--transparent);
  --input-main-placeholder: var(--purple-100);
  --input-main-border: var(--purple-100);
  --input-form-background: var(--purple-25);
  --input-form-placeholder: var(--purple-100);
  --input-form-border: var(--transparent);
  --input-toolbar-background: var(--purple-400);
  --input-toolbar-placeholder: var(--purple-100);
  --input-toolbar-border: var(--transparent);
  --input-editor-background: var(--purple-400);
  --input-editor-placeholder: var(--purple-100);
  --input-editor-border: var(--transparent);
  /* TEXT */
  --text-main-primary: var(--white);
  --text-main-secondary: var(--purple-100);
  --text-main-support: var(--purple-200);
  --text-form-primary: var(--purple-600);
  --text-form-secondary: var(--purple-200);
  --text-form-support: var(--purple-100);
  --text-toolbar-primary: var(--white);
  --text-toolbar-secondary: var(--purple-100);
  --text-toolbar-support: var(--purple-200);
  --text-editor-primary: var(--white);
  --text-editor-secondary: var(--purple-100);
  --text-editor-support: var(--purple-200);
  /* LINKS */
  --link-main: var(--purple-100);
  --link-form: var(--purple-200);
  --link-toolbar: var(--purple-100);
  --link-editor: var(--purple-100);
  /* BUTTONS */
  --button-main-primary-background: linear-gradient(
    76.27deg,
    rgb(113, 5, 179) 10.12%,
    rgb(165, 95, 255) 100.91%
  );
  --button-main-primary-content: var(--white);
  --button-main-secondary-background: var(--transparent);
  --button-main-secondary-content: var(--purple-100);
  --button-main-support-background: var(--transparent);
  --button-main-support-content: var(--purple-100);
  --button-form-primary-background: var(--purple-200);
  --button-form-primary-content: var(--white);
  --button-form-secondary-background: var(--transparent);
  --button-form-secondary-content: var(--purple-200);
  --button-form-support-background: var(--transparent);
  --button-form-support-content: var(--purple-100);
  --button-toolbar-primary-background: var(--purple-200);
  --button-toolbar-primary-content: var(--white);
  --button-toolbar-secondary-background: var(--transparent);
  --button-toolbar-secondary-content: var(--purple-100);
  --button-toolbar-support-background: var(--transparent);
  --button-toolbar-support-content: var(--purple-100);
  --button-editor-primary-background: var(--purple-200);
  --button-editor-primary-content: var(--white);
  --button-editor-secondary-background: var(--transparent);
  --button-editor-secondary-content: var(--purple-100);
  --button-editor-support-background: var(--transparent);
  --button-editor-support-content: var(--purple-100);
  --button-disabled-primary-background: var(--disabled);
  --button-disabled-primary-content: var(--white);
  --button-disabled-secondary-background: var(--transparent);
  --button-disabled-secondary-content: var(--disabled);
  --button-disabled-support-background: var(--transparent);
  --button-disabled-support-content: var(--disabled);
  /* TOOLTIP */
  --tooltip-background: var(--purple-350);
  --tooltip-content: var(--purple-50);
  /* INFORMATION */
  --success: #26efef;
  --success-saturated: #00af54;
  --failure: #ef2686;
  --warning-100: #f79824;
  --warning-100-20: #f7982433;

  /** TEXT SIZES **/
  --text-size-xs: 0.6em;
  --text-size-s: 0.8em;
  --text-size-m: 1em;
  --text-size-l: 1.8em;
  --text-size-xl: 2.4em;
  --text-header: 13px;
  --text-label: 12px;

  /** SPACINGS **/
  --max-width: 1500px;
  --size-xs: 4px;
  --size-s: 10px;
  --size-m: 20px;
  --size-l: 40px;
  --size-xl: 80px;

  /** HEIGHTS **/
  --button-height: 40px;
  --input-height: 40px;

  /** RADIUS **/
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 32px;
}

.pf.default-theme .pf.app-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(./assets/images/app-background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.pf.pf.default-theme .pf.editor-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: var(--container-editor-background);
}

.pf-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: var(--purple-saturated);
}

.pf-scroll.md::-webkit-scrollbar {
  width: 8px; /* Change the width */
  height: 4px;
  background: var(--purple-saturated);
}

.pf-scroll::-webkit-scrollbar-thumb {
  background: var(--purple-200);
  border-radius: 10px;
}

.pf-scroll::-webkit-scrollbar-thumb:hover {
  background: var(--purple-100);
}

/* >>> RESPONSIVENESS <<< */

/* @media only screen and (max-width: 700px) {
  html {
    font-size: 90%;
  }
} */
