.component-selection::-webkit-scrollbar {
  width: 6px;
  background: var(--purple-saturated);
}

.component-selection::-webkit-scrollbar-thumb {
  background: var(--purple-200);
  border-radius: 10px;
}

.component-selection::-webkit-scrollbar-thumb:hover {
  background: var(--purple-100);
}
