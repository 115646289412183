.var_item:hover {
  background-color: #342c49;
}

.var_item:hover > i {
  color: #ad90ff !important;
}

.vars_container {
  overflow: auto;
}
.vars_container::-webkit-scrollbar {
  width: 6px;
  background: var(--purple-500);
}

.vars_container::-webkit-scrollbar-thumb {
  background: #655e78;
  border-radius: 10px;
}

.vars_container::-webkit-scrollbar-thumb:hover {
  background: #ad90ff;
}
