.pf.table-editor-footer-container {
  height: 40px;
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100vw;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.pf.table-editor-footer-container::-webkit-scrollbar {
  display: none;
}