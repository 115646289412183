.pf.pfitemcard-container {
  position: relative;
  height: 200px;
  padding: var(--size-s) var(--size-l) var(--size-s) var(--size-l);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid var(--secondary);
  border-radius: var(--radius-l);
}

.pf.pfitemcard-children-wrapper {
  display: inline-block;
}

.pf.pfitemcard-menu {
  position: absolute;
  top: 20px;
  right: 15px;
}

.pf.pfitemcard-menu-options {
  position: absolute;
  top: 60px;
  right: 25px;
  display: flex;
  flex-direction: column;
  background: var(--primary);
  border-radius: var(--radius-s);
  padding: var(--size-xs);
  z-index: 1;
  opacity: 0;
  transition: all 300ms ease;
  pointer-events: none;
}

.pf.pfitemcard-menu-options.active {
  opacity: 1;
  pointer-events: all;
}

.pf.pfitemcard-menu-options>.pf.button {
  color: white;
}

.pf.pfitemcard-menu-options>.pf.button:not(:last-child) {
  border-bottom: 1px solid #FFF5;
}

.pf.pfitemcard-button {
  position: absolute;
  bottom: 20px;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--size-xs);
  border-radius: 50%;
  color: var(--container-main-background);
  background-color: var(--secondary);
  opacity: 0;
  transition: all 300ms ease;
}

.pf.pfitemcard-container:hover .pf.pfitemcard-button {
  opacity: 1;
}