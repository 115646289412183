.PFColorpicker {
}

.PFColorpickerToggler {
	border-radius: var(--radius-s);
	padding: 8px;
	height: 30px;
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 12px;
	background: var(--input-toolbar-background);
}

.PFColorpickerButton {
	border: 1px solid white;
	border-radius: 4px;
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.PFColorpickerPanel {
	border-radius: 8px;
	overflow: hidden;
	margin: 0 auto;
	width: 200px;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 2;
	background-color: white;
}

.PFColorpickerPanel .sketch-picker {
	width: auto !important;
	padding: 0px !important;
	background: transparent !important;
	border: none !important;
	box-shadow: none !important;
}

.PFColorpickerPanel .sketch-picker > div:last-child {
	display: none !important;
}

.PFColorpickerPanel .sketch-picker > div:not(first-child) {
	padding: 0 4px;
}

.PFColorpickerClearValue {
	border-radius: 4px;
	padding: 4px;
	margin: 4px;
	width: auto;
	display: flex;
	justify-content: space-around;
	font-size: 12px;
	background-color: var(--purple-400);
	color: var(--purple-100);
}

.PFColorpickerClearValue:hover {
	background-color: var(--purple-300);
	color: var(--white);
}
