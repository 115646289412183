.pf.line {
  border-bottom: 1px solid var(--strokes);
  border-right: 1px solid var(--strokes);
}

.pf.pf-container.main .pf.line {
  border-color: var(--stroke-main);
}

.pf.pf-container.toolbar .pf.line {
  border-color: var(--stroke-toolbar);
}

.pf.pf-container.editor .pf.line {
  border-color: var(--stroke-editor);
}

.pf.pf-container.form .pf.line {
  border-color: var(--stroke-form);
}