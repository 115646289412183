.pefai-canvas-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
}

.pefai-canvas {
	margin-top: 5px;
	width: calc(100% - 325px);
	height: calc(100% - 5px);
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	background-color: var(--purple-700);
	background-image: radial-gradient(var(--purple-200) 0.5px, transparent 0);
	background-size: 15px 15px;
	background-position: -19px -19px;
}

.pefai-component-menu {
	width: 280px;
	background: var(--container-editor-background);
	position: fixed;
	right: 0;
}
