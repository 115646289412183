.pf-breadcrumbs {
	width: 100%;
	height: 30px;
	background-color: var(--purple-500);
	z-index: 10;
}

.pf-breadcrumbs ul {
	padding: 0;
	margin: 0;
	height: 100%;
	display: flex;
	position: relative;
	align-items: center;
	list-style-type: none;
	overflow-x: auto;
	overflow-y: hidden;
}

.pf-breadcrumbs ul::-webkit-scrollbar {
	width: 6px;
	height: 4px;
	background: var(--purple-saturated);
}

.pf-breadcrumbs ul::-webkit-scrollbar-thumb {
	background: var(--purple-200);
	border-radius: 10px;
}

.pf-breadcrumbs ul::-webkit-scrollbar-thumb:hover {
	background: var(--purple-100);
}

.pf-breadcrumbs ul li {
	padding: 0 8px;
	font-size: 12px;
	color: var(--purple-100);
	cursor: default;
}

.pf-breadcrumbs ul li:hover {
	color: var(--purple-50);
}

.pf-breadcrumbs ul li:last-child {
	color: var(--white);
}

.pf-breadcrumbs ul li:not(:last-child)::after {
	content: '';
	position: absolute;
	top: 0;
	width: 7px;
	height: 100%;
	background-position-y: -1px;
	background-image: url('./Breadcrumb-spacer.svg');
	background-repeat: no-repeat;
}

.pf-breadcrumbs span {
	padding: 0 16px;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-align: center;
	vertical-align: middle;
}
