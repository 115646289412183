.component-library::-webkit-scrollbar {
  width: 5px;
  background: var(--purple-saturated);
}

.component-library::-webkit-scrollbar-thumb {
  background: var(--purple-200);
  border-radius: 10px;
}

.component-library::-webkit-scrollbar-thumb:hover {
  background: var(--purple-100);
}