.pf.text-component {
  color: var(--text-main-primary);
  margin: 0;
}

.pf.text-component.clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pf.pf-container.main .pf.text-component.primary {
  color: var(--text-main-primary);
}

.pf.pf-container.toolbar .pf.text-component.primary {
  color: var(--text-toolbar-primary);
}

.pf.pf-container.editor .pf.text-component.primary {
  color: var(--text-editor-primary);
}

.pf.pf-container.form .pf.text-component.primary {
  color: var(--text-form-primary);
}

.pf.pf-container.main .pf.text-component.secondary {
  color: var(--text-main-secondary);
}

.pf.pf-container.toolbar .pf.text-component.secondary {
  color: var(--text-toolbar-secondary);
}

.pf.pf-container.editor .pf.text-component.secondary {
  color: var(--text-editor-secondary);
}

.pf.pf-container.form .pf.text-component.secondary {
  color: var(--text-form-secondary);
}

.pf.pf-container.main .pf.text-component.support {
  color: var(--text-main-support);
}

.pf.pf-container.toolbar .pf.text-component.support {
  color: var(--text-toolbar-support);
}

.pf.pf-container.editor .pf.text-component.support {
  color: var(--text-editor-support);
}

.pf.pf-container.form .pf.text-component.support {
  color: var(--text-form-support);
}