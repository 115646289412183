.pf.select-container {
  position: relative;
  width: 100%;
}

.pf.select-label {
  display: block;
  margin-bottom: var(--size-xs);
}

.pf.select-error {
  display: block;
  margin-top: var(--size-s);
  font-size: var(--text-size-s);
  color: var(--failure);
  margin-top: var(--size-xs);
}

.pf.select {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  border: none;
  background: none;
  transition: opacity 200ms ease;
  font-size: var(--text-size-m);
  text-align: start;
}

.pf.select:hover {
  opacity: 0.7;
}

.pf.pf-container.main .pf.select-label {
  color: var(--text-main-primary);
}

.pf.pf-container.toolbar .pf.select-label {
  color: var(--text-toolbar-primary);
}

.pf.pf-container.editor .pf.select-label {
  color: var(--text-editor-primary);
}

.pf.pf-container.form .pf.select-label {
  color: var(--text-form-primary);
}

.pf.button.select {
  border-radius: var(--radius-s);
}

.pf.pf-container.main .pf.button.select {
  border: 1px solid var(--stroke-main);
  border-radius: 20px;
}

.pf.pf-container.toolbar .pf.button.select {
  border: 1px solid var(--stroke-toolbar);
}

.pf.pf-container.editor .pf.button.select {
  border: 1px solid var(--stroke-editor);
}

.pf.pf-container.editor .pf.button.select.header {
  border: none;
  background-color: var(--container-toolbar-background);
  border-radius: var(--radius-l);
  padding: 0 var(--size-m);
}

.pf.pf-container.form .pf.button.select {
  border: 1px solid var(--stroke-form);
}

.pf.pf-container .pf.button.select.error:not(.focus) {
  border: 1px solid var(--failure);
}

.pf.pf-container.main .pf.button.select.focus {
  border: 1px solid var(--button-main-support-content);
}

.pf.pf-container.toolbar .pf.button.select.focus {
  border: 1px solid var(--button-toolbar-support-content);
}

.pf.pf-container.editor .pf.button.select.focus {
  border: 1px solid var(--button-editor-support-content);
}

.pf.pf-container.form .pf.button.select.focus {
  border: 1px solid var(--button-form-support-content);
}
