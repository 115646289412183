.pf.instruction-container {
  position: relative;
}

.pf.instruction-options {
  position: absolute;
  top: 15px;
  left: -50px;
  opacity: 0;
  transition: all 300ms ease;
}

.pf.instruction-container:hover .pf.instruction-options {
  opacity: 1;
}