.pf.pf-container.main {
  .pf.button.icon-button {
    background-color: var(--stroke-main);
  }
}

.pf.pf-container.toolbar {
  .pf.button.icon-button {
    background-color: var(--stroke-toolbar);
  }
}

.pf.pf-container.editor {
  .pf.button.icon-button {
    background-color: var(--stroke-editor);
  }
}

.pf.pf-container.form {
  .pf.button.icon-button {
    background-color: var(--stroke-form);
  }
}