.pf.file-input-container {
  cursor: pointer;
  position: relative;
  font-size: var(--text-size-m);
  border-radius: var(--radius-s);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--input-height);
  padding: 0 var(--size-s);
}

.pf.file-input-container:hover {
  opacity: 0.8;
}

.pf.input.file {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.pf.file-input-placeholder {
  opacity: 0.6;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pf.input-icon {
  font-size: 1.3em;
}

.pf.pf-container.main .pf.file-input-container {
  color: var(--text-main-primary);
  background: var(--input-main-background);
  border: 1px solid var(--input-main-border);
  border-radius: var(--radius-l);
  padding: 0 var(--size-m);
}

.pf.pf-container.main .pf.input-icon,
.pf.pf-container.main .pf.file-input-placeholder {
  color: var(--input-main-placeholder);
}

.pf.pf-container.toolbar .pf.file-input-container {
  background: var(--input-toolbar-background);
}

.pf.pf-container.toolbar .pf.input-icon,
.pf.pf-container.toolbar .pf.file-input-placeholder {
  color: var(--input-toolbar-placeholder);
}

.pf.pf-container.editor .pf.file-input-container {
  background: var(--input-editor-background);
}

.pf.pf-container.editor .pf.input-icon,
.pf.pf-container.editor .pf.file-input-placeholder {
  color: var(--input-editor-placeholder);
}

.pf.pf-container.form .pf.file-input-container {
  background: var(--input-form-background);
}

.pf.pf-container.form .pf.input-icon,
.pf.pf-container.form .pf.file-input-placeholder {
  color: var(--input-form-placeholder);
}

.pf.pf-container .pf.file-input-container.error {
  border: 1px solid var(--failure);
}