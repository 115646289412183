.pf.loader.area {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .pf.loader {
  color: var(--text-main-primary);
}

.pf.pf-container.main .pf.loader {
  color: var(--text-main-primary);
}

.pf.pf-container.toolbar .pf.loader {
  color: var(--text-toolbar-primary);
}

.pf.pf-container.editor .pf.loader {
  color: var(--text-editor-primary);
}

.pf.pf-container.form .pf.loader {
  color: var(--text-form-primary);
} */