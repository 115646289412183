.pf.button {
  cursor: pointer;
  border: none;
  background: none;
  transition: opacity 200ms ease;
  font-size: var(--text-size-m);
  font-family: var(--theme-font);
  padding: 0 var(--size-s)
}

.pf.button:hover {
  opacity: 0.7;
}

.pf.button.primary,
.pf.button.secondary,
.pf.button.support {
  height: var(--button-height);
}

.pf.pf-container.main .pf.button.primary {
  background: var(--button-main-primary-background);
  color: var(--button-main-primary-content);
  border-radius: calc(var(--button-height) / 2);
}

.pf.pf-container.toolbar .pf.button.primary {
  background: var(--button-toolbar-primary-background);
  color: var(--button-toolbar-primary-content);
  border-radius: var(--radius-s);
}

.pf.pf-container.editor .pf.button.primary {
  background: var(--button-editor-primary-background);
  color: var(--button-editor-primary-content);
  border-radius: calc(var(--button-height) / 2);
}

.pf.pf-container.form .pf.button.primary {
  background: var(--button-form-primary-background);
  color: var(--button-form-primary-content);
  border-radius: var(--radius-s);
}

.pf.pf-container.main .pf.button.secondary {
  background: var(--button-main-secondary-background);
  color: var(--button-main-secondary-content);
  border-radius: calc(var(--button-height) / 2);
  border: 1px solid var(--button-main-secondary-content);
}

.pf.pf-container.toolbar .pf.button.secondary {
  background: var(--button-toolbar-secondary-background);
  color: var(--button-toolbar-secondary-content);
  border-radius: var(--radius-s);
  border: 1px solid var(--button-toolbar-secondary-content);
}

.pf.pf-container.editor .pf.button.secondary {
  background: var(--button-editor-secondary-background);
  color: var(--button-editor-secondary-content);
  border-radius: calc(var(--button-height) / 2);
  border: 1px solid var(--button-editor-secondary-content);
}

.pf.pf-container.form .pf.button.secondary {
  background: var(--button-form-secondary-background);
  color: var(--button-form-secondary-content);
  border-radius: var(--radius-s);
  border: 1px solid var(--button-form-secondary-content);
}

.pf.pf-container.main .pf.button.support {
  background: var(--button-main-support-background);
  color: var(--button-main-support-content);
}

.pf.pf-container.toolbar .pf.button.support {
  background: var(--button-toolbar-support-background);
  color: var(--button-toolbar-support-content);
}

.pf.pf-container.editor .pf.button.support {
  background: var(--button-editor-support-background);
  color: var(--button-editor-support-content);
}

.pf.pf-container.form .pf.button.support {
  background: var(--button-form-support-background);
  color: var(--button-form-support-content);
}

.pf.button.disabled {
  opacity: 0.5;
}

.pf.button.disabled:hover {
  opacity: 0.5;
}

.pf.pf-container .pf.button.primary.disabled {
  background: var(--button-disabled-primary-background);
  color: var(--button-disabled-primary-content);
}

.pf.pf-container .pf.button.secondary.disabled {
  background: var(--button-disabled-secondary-background);
  color: var(--button-disabled-secondary-content);
  border: 1px solid var(--button-disabled-secondary-content);
}

.pf.pf-container .pf.button.support.disabled {
  background: var(--button-disabled-support-background);
  color: var(--button-disabled-support-content);
}