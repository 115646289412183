.pf.pf-container.main {
  .pf.code-editor.text {
    color: var(--text-main-primary);
  }

  .pf.code-editor.string {
    color: var(--text-main-secondary);
  }

  .pf.code-editor.value {
    color: var(--text-main-secondary);
  }

  .pf.code-editor.param {
    color: var(--text-main-support);
  }
}

.pf.pf-container.toolbar {
  .pf.code-editor.text {
    color: var(--text-toolbar-primary);
  }

  .pf.code-editor.string {
    color: var(--text-toolbar-secondary);
  }

  .pf.code-editor.value {
    color: var(--text-toolbar-secondary);
  }

  .pf.code-editor.param {
    color: var(--text-toolbar-support);
  }
}

.pf.pf-container.editor {
  .pf.code-editor.text {
    color: var(--text-editor-primary);
  }

  .pf.code-editor.string {
    color: var(--text-editor-secondary);
  }

  .pf.code-editor.value {
    color: var(--text-editor-secondary);
  }

  .pf.code-editor.param {
    color: var(--text-editor-support);
  }
}

.pf.pf-container.form {
  .pf.code-editor.text {
    color: var(--text-form-primary);
  }

  .pf.code-editor.string {
    color: var(--text-form-secondary);
  }

  .pf.code-editor.value {
    color: var(--text-form-secondary);
  }

  .pf.code-editor.param {
    color: var(--text-form-support);
  }
}